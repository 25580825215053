<template>
  <div class="bottom-link flex-center-between">
    <div class="link flex-column-center" v-for="(item, i) in linkList" :key="i">
      <div class="icon">
        <i class="iconfont" :class="`icon-${item.icon}`"></i>
      </div>
      <div class="label">{{ item.name }}</div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {}
  },
  computed: {
    linkList() {
      return [
        {
          icon: 'aixin',
          name: this.$t('zheng-pin')
        },
        {
          icon: 'control-backward',
          name: this.$t('qi-tian-tui-huo')
        },
        {
          icon: 'yunfei',
          name: this.$t('yun-fei-zhe-kou')
        },
        {
          icon: 'qianbao',
          name: this.$t('an-quan-zhi-fu')
        }
      ]
    }
  }
}
</script>