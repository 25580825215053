<template>
  <div class="wap-order-page">
    <div class="pay-top">
      <div class="back" @click="back">
        <i class="el-icon-arrow-left"></i>
      </div>
      <div class="label">{{ $t('dai-fu-kuan') }}</div>
    </div>

    <div class="pay-list">
      <div class="label">{{ $t('fu-kuan-fang-shi') }}</div>
      <div class="pay flex-center" v-for="(item, i) in payList" :key="i">
        <div class="img">
          <img :src="item.img" alt="" :class="i == 3 ? 'small' : ''" />
        </div>
        <div class="flex-1">
          <div class="name">
            {{ item.name }}
            <span class="gray" :class="i == 0 ? 'price' : ''">
              ({{ i == 0 ? `$${userMoney}` : item.desc }})
            </span>
          </div>
        </div>
        <div class="">
          <Checkbox
            :disabled="i > 0"
            v-model="item.checked"
            checked-color="#9d48f7"
            icon-size="16px"
          ></Checkbox>
        </div>
      </div>
    </div>

    <div class="bottom-action flex-center-between">
      <div class="flex flex-1">
        <div class="flex-1">
          {{ $t('zong-ji-0') }}
          <span class="price">${{ totalMoney }}</span>
        </div>
      </div>
      <div>
        <Button @click="submit">{{ $t('zhi-fu') }}</Button>
      </div>
    </div>

    <Popup v-model="showPopup" @cancel="cancel" position="bottom" round>
      <div class="password-popup">
        <div class="title">
          {{ $t('qing-shu-ru-jiao-yi-mi-ma') }}
          <i @click="cancel" class="el-icon-close"></i>
        </div>
        <PasswordInput :focused="showPopup" :value="password"></PasswordInput>
        <NumberKeyboard
          :show="showPopup"
          v-model="password"
          @input="doPay"
          @hide="cancel"
          :maxlength="6"
        ></NumberKeyboard>
      </div>
    </Popup>
  </div>
</template>
<script>
import bottomLink from '@/components/bottomLink'
import {
  getWalletInfo,
  addOrder,
  orderPay,
  shippingAddressList
} from '@/api/user'
import { Checkbox, Button, PasswordInput, NumberKeyboard, Popup } from 'vant'
import headerBar from '@/components/header'
export default {
  components: {
    bottomLink,
    Checkbox,
    NumberKeyboard,
    PasswordInput,
    Button,
    Popup,
    headerBar
  },
  data() {
    return {
      password: '',
      showPopup: false,
      orderId: '',
      payList: [
        {
          name: this.$t('yu-e'),
          img: require('@/assets/imgs/icon-pay1.png'),
          desc: '',
          checked: true
        },
        {
          name: this.$t('bei-bao'),
          img: require('@/assets/imgs/icon-pay2.png'),
          desc: this.$t('nin-suo-zai-de-di-qu-zan-wei-kai-fang')
        },
        {
          name: this.$t('visa'),
          img: require('@/assets/imgs/icon-pay3.png'),
          desc: this.$t('nin-suo-zai-de-di-qu-zan-wei-kai-fang-0')
        },
        {
          name: this.$t('wan-shi-da-ka'),
          img: require('@/assets/imgs/icon-pay4.png'),
          desc: this.$t('nin-suo-zai-de-di-qu-zan-wei-kai-fang-1')
        }
      ],
      userMoney: '',
      totalMoney: ''
    }
  },
  computed: {},
  mounted() {
    this.orderId = this.$route.query.id
    this.totalMoney = this.$route.query.price
    this.initMoney()
  },
  methods: {
    back() {
      this.$router.go(-1)
    },
    initMoney() {
      getWalletInfo().then((res) => {
        let list = res.data.Items[0]
        this.userMoney = list.Balance
      })
    },
    cancel() {
      this.showPopup = false
    },
    submit() {
      this.showPopup = true
    },
    doPay() {
      setTimeout(() => {
        if (this.password.length == 6) {
          orderPay({
            OrderID: parseFloat(this.orderId),
            Password: parseInt(this.password)
          }).then((res) => {
            if (res.code == 0) {
              this.$toast(this.$t('zhi-fu-cheng-gong'))
              this.showPopup = false
              this.$store.state.initCar = !this.$store.state.initCar
              setTimeout(() => {
                this.$router.push({
                  name: 'wapUserOrder',
                  query: {
                    type: 'init'
                  }
                })
              }, 1500)
            } else {
              this.$toast(res.msg)
            }
          })
        }
      }, 300)
    }
  }
}
</script>